<template>
	<div>
		<a-modal
			v-drag-modal
			v-model="userModal.show"
			:title="$t(userModal.type === 'add' ? 'page.addUser' : 'page.editUser')"
			:destroyOnClose="true"
			:confirm-loading="userModal.loading"
			@ok="handleUserModalOk"
		>
			<div class="userModal">
				<a-form-model
					:ref="userModal.form"
					:rules="userModal.rules"
					:model="userModal.data"
					:labelCol="userModal.labelCol"
					:wrapperCol="userModal.wrapperCol"
				>
					<a-form-model-item v-if="userModal.type === 'edit'" :label="$t('table.Permission.User.id')">
						<a-input :disabled="userModal.type === 'edit'" v-model="userModal.data.list_id"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Permission.User.username')" prop="list_name">
						<a-input v-model="userModal.data.list_name"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Permission.User.displayName')" prop="list_displayName">
						<a-input v-model="userModal.data.list_displayName"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Permission.User.password')" prop="list_password">
						<a-input v-model="userModal.data.list_password"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Permission.User.mobile')">
						<a-input v-model="userModal.data.list_mobile"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Permission.User.email')">
						<a-input v-model="userModal.data.list_email"></a-input>
					</a-form-model-item>

					<a-form-model-item :label="$t('page.selectRole')" prop="role_id_list">
						<a-checkbox-group v-model="userModal.data.role_id_list">
							<a-row v-for="option in permission.roleIdList" :key="option.value">
								<a-checkbox :value="option.value">
									{{ $t(option.label) }}
								</a-checkbox>
							</a-row>
						</a-checkbox-group>
					</a-form-model-item>
				</a-form-model>
			</div>
		</a-modal>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="btn">
				<a-button class="button" type="primary" @click="showUserModal('add')" v-t.preserve="'page.addUser'"></a-button>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="handleEdit(columnData)">{{ $t('page.edit') }}</a>
				<span v-if="columnData.enabled === '0'">
					<a-divider type="vertical" />
					<a @click="handleDelete(columnData)">{{ $t('page.delete') }}</a>
				</span>
			</template>
		</search-list-table>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiCreateUser, apiQueryUserList, apiDeleteUser, apiUpdateUser, apiActivateUser, apiUserSetRole, apiQueryRoleList } from '@/api/role'

export default {
	data() {
		return {
			userModal: {
				form: 'userModalForm',
				labelCol: { span: 6 },
				wrapperCol: { span: 12 },
				loading: false,
				show: false,
				type: 'add',
				data: {
					list_id: '',
					list_name: '',
					list_displayName: '',
					list_password: '',
					list_mobile: '',
					list_email: '',
					role_id_list: []
				},
				rules: {
					list_name: [
						{
							required: true,
							whitespace: true
						}
					],
					list_displayName: [
						{
							required: true,
							whitespace: true
						}
					],
					list_password: [
						{
							required: true,
							whitespace: true
						}
					],
					role_id_list: [
						{
							required: false
						}
					]
				}
			}
		}
	},
	computed: {
		...mapGetters(['permission']),
		formSearchList() {
			return [
				{
					label: 'table.Permission.User.username',
					type: 'input',
					validateField: ['username']
				},
				{
					label: 'table.Permission.User.roles',
					type: 'select',
					validateField: ['roleId'],
					selectOption: this.permission.roleIdListAll,
					dataPreType: {
						value: 'value',
						text: 'label'
					}
				},
				{
					label: 'table.Permission.User.enabled',
					type: 'select',
					validateField: ['enabled'],
					selectOption: [0, 1],
					prefix: 'table.Permission.User.enabled_map'
				}
			]
		},
		columns() {
			return [
				{
					title: this.$t('table.Permission.User.username'),
					dataIndex: 'username',
					align: 'center'
				},
				{
					title: this.$t('table.Permission.User.displayName'),
					dataIndex: 'displayName',
					align: 'center'
				},
				{
					title: this.$t('table.Permission.User.mobile'),
					dataIndex: 'mobile',
					align: 'center'
				},
				{
					title: this.$t('table.Permission.User.email'),
					dataIndex: 'email',
					align: 'center'
				},
				{
					title: this.$t('table.Permission.User.roles'),
					dataIndex: 'roles',
					align: 'center',
					customRender: (v) => {
						let vhtml = v.map((item) => {
							return <p>{this.permission.roleIdListMap[item]}</p>
						})
						return vhtml
					}
				},
				{
					title: this.$t('table.Permission.User.enabled'),
					dataIndex: 'enabled',
					align: 'center',
					customRender: (v) => (v ? this.$t(`table.Permission.User.enabled_map.${v}`) : '-')
				},
				{
					title: this.$t('operate.operate'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	async mounted() {
		let roleIdList = (await apiQueryRoleList({ pageNum: 0, pageSize: 999999 })).pageItems
		this.$store.commit('permission/setRoleId', roleIdList)
	},
	methods: {
		initDataSource(initParams) {
			return apiQueryUserList(initParams)
		},
		resetUserModal() {
			this.userModal.data = {
				list_id: '',
				list_name: '',
				list_mobile: '',
				list_email: '',
				role_id_list: []
			}
		},
		showUserModal(type, data) {
			this.userModal.type = type
			if (type === 'add') {
				this.resetUserModal()
				this.userModal.rules.list_password[0].required = true
			}
			if (type === 'edit') {
				this.userModal.rules.list_password[0].required = false
				this.userModal.data = {
					list_id: data.id,
					list_email: data.email,
					list_displayName: data.displayName,
					list_name: data.username,
					list_mobile: data.mobile,
					role_id_list: data.roles
				}
			}

			this.userModal.show = true
		},
		async handleEdit(obj) {
			this.showUserModal('edit', obj)
		},
		async handleUserModalOk() {
			await this.$refs.userModalForm.validate()
			let data = this.userModal.data
			let params = {
				id: data.list_id,
				username: data.list_name,
				displayName: data.list_displayName,
				email: data.list_email,
				mobile: data.list_mobile,
				password: data.list_password
			}

			params = this.removeUnuseParam(params)
			this.userModal.loading = true
			let userId = params.id
			try {
				if (this.userModal.type === 'add') {
					let res = await apiCreateUser(params)
					userId = res
				} else if (this.userModal.type === 'edit') {
					let res = await apiUpdateUser(params)
				}
				await apiUserSetRole({
					userId: userId,
					roleIds: data.role_id_list
				})
				this.userModal.loading = false
				this.userModal.show = false
				this.$refs.searchListTable.initTable(1)
			} finally {
				this.userModal.loading = false
			}
		},
		async handleDelete(obj) {
			this.$confirm({
				content: this.$t('page.confirm'),
				onOk: async () => {
					let params = {
						id: obj.id
					}
					try {
						let res = await apiDeleteUser(params)
						this.$refs.searchListTable.initTable(1)
					} catch (e) {
						this.$error({
							content: e.message
						})
					}
				}
			})
		},
		removeUnuseParam(params) {
			for (let key in params) {
				// 移除未定义参数
				if (params[key] === undefined || params[key] === '') {
					delete params[key]
				}
			}
			return params
		}
	}
}
</script>

<style scoped lang="less">
.title button {
	float: right;
	margin-right: 10px;
}

.export .download {
	float: right;
	margin-right: 10px;
}
</style>
